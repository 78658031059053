<template>
    <div>
        <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
            <div>
                <h2 class="text-lg leading-6 font-medium text-gray-900">Profile</h2>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                    This information will be displayed publicly so be careful what you share.
                </p>
            </div>

            <div class="flex flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-6">
                <div class="flex-grow space-y-6">
                    <div class="space-y-1">
                        <label for="username" class="block text-sm font-medium leading-5 text-gray-700">
                            Username
                        </label>
                        <div class="rounded-md shadow-sm flex">
                                              <span class="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
                                                workcation.com/
                                              </span>
                            <input id="username" class="form-input flex-grow block w-full min-w-0 rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" value="lisamarie">
                        </div>
                    </div>

                    <div class="space-y-1">
                        <label for="about" class="block text-sm leading-5 font-medium text-gray-700">
                            About
                        </label>
                        <div class="rounded-md shadow-sm">
                            <textarea id="about" rows="3" class="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"></textarea>
                        </div>
                        <p class="mt-2 text-sm text-gray-500">
                            Brief description for your profile. URLs are hyperlinked.
                        </p>
                    </div>
                </div>

                <div class="flex-grow space-y-1 lg:flex-grow-0 lg:flex-shrink-0">
                    <p class="block text-sm leading-5 font-medium text-gray-700" aria-hidden="true">
                        Photo
                    </p>
                    <div class="lg:hidden">
                        <div class="flex items-center">
                            <div class="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12" aria-hidden="true">
                                <img class="rounded-full h-full w-full" src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=80h" alt="">
                            </div>
                            <div class="ml-5 rounded-md shadow-sm">
                                <div class="group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center focus-within:border-blue-300 focus-within:shadow-outline-blue focus-within:text-gray-900 transition duration-150 ease-in-out">
                                    <label for="user_photo" class="relative text-sm leading-4 font-medium text-gray-700 group-hover:text-gray-500 transition duration-150 ease-in-out pointer-events-none">
                                        <span>Change</span>
                                        <span class="sr-only"> user photo</span>
                                    </label>
                                    <input id="user_photo" type="file" class="absolute w-full h-full opacity-0 cursor-pointer">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hidden relative rounded-full overflow-hidden lg:block transition duration-150 ease-in-out">
                        <img class="relative rounded-full w-40 h-40" src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80" alt="">
                        <label class="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm leading-5 font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100 transition duration-150 ease-in-out">
                            <span>Change</span>
                            <span class="sr-only"> user photo</span>
                            <input type="file" class="absolute inset-0 w-full h-full opacity-0 cursor-pointer">
                        </label>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 sm:col-span-6">
                    <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">First name</label>
                    <input id="first_name" class="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                </div>

                <div class="col-span-12 sm:col-span-6">
                    <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">Last name</label>
                    <input id="last_name" class="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                </div>

                <div class="col-span-12">
                    <label for="url" class="block text-sm font-medium leading-5 text-gray-700">URL</label>
                    <input id="url" class="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                </div>

                <div class="col-span-12 sm:col-span-6">
                    <label for="company" class="block text-sm font-medium leading-5 text-gray-700">Company</label>
                    <input id="company" class="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                </div>
            </div>
        </div>

        <!-- Privacy section -->
        <div class="pt-6 space-y-4 divide-y divide-gray-200">
            <div class="px-4 space-y-2 sm:px-6">
                <div>
                    <h2 class="text-lg leading-6 font-medium text-gray-900">Privacy</h2>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                        Ornare eu a volutpat eget vulputate. Fringilla commodo amet.
                    </p>
                </div>
                <ul class="divide-y divide-gray-200">
                    <li class="py-4 flex items-center justify-between space-x-4">
                        <div class="flex flex-col">
                            <p id="privacy-option-label-1" class="text-sm leading-5 font-medium text-gray-900">
                                Available to hire
                            </p>
                            <p id="privacy-option-description-1" class="text-sm leading-5 text-gray-500">
                                Nulla amet tempus sit accumsan. Aliquet turpis sed sit lacinia.
                            </p>
                        </div>
                        <!-- On: "bg-teal-500", Off: "bg-gray-200" -->
                        <span role="checkbox" tabindex="0" aria-checked="true" aria-labelledby="privacy-option-label-1" aria-describedby="privacy-option-description-1" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline">
                      <!-- On: "translate-x-5", Off: "translate-x-0" -->
                                          <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"></span>
                                        </span>
                    </li>
                    <li class="py-4 flex items-center justify-between space-x-4">
                        <div class="flex flex-col">
                            <p id="privacy-option-label-2" class="text-sm leading-5 font-medium text-gray-900">
                                Make account private
                            </p>
                            <p id="privacy-option-description-2" class="text-sm leading-5 text-gray-500">
                                Pharetra morbi dui mi mattis tellus sollicitudin cursus pharetra.
                            </p>
                        </div>
                        <!-- On: "bg-teal-500", Off: "bg-gray-200" -->
                        <span role="checkbox" tabindex="0" aria-checked="false" aria-labelledby="privacy-option-label-2" aria-describedby="privacy-option-description-2" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline">
                      <!-- On: "translate-x-5", Off: "translate-x-0" -->
                                          <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"></span>
                                        </span>
                    </li>
                    <li class="py-4 flex items-center justify-between space-x-4">
                        <div class="flex flex-col">
                            <p id="privacy-option-label-3" class="text-sm leading-5 font-medium text-gray-900">
                                Allow commenting
                            </p>
                            <p id="privacy-option-description-3" class="text-sm leading-5 text-gray-500">
                                Integer amet, nunc hendrerit adipiscing nam. Elementum ame
                            </p>
                        </div>
                        <!-- On: "bg-teal-500", Off: "bg-gray-200" -->
                        <span role="checkbox" tabindex="0" aria-checked="true" aria-labelledby="privacy-option-label-3" aria-describedby="privacy-option-description-3" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline">
                      <!-- On: "translate-x-5", Off: "translate-x-0" -->
                                          <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"></span>
                                        </span>
                    </li>
                    <li class="py-4 flex items-center justify-between space-x-4">
                        <div class="flex flex-col">
                            <p id="privacy-option-label-4" class="text-sm leading-5 font-medium text-gray-900">
                                Allow mentions
                            </p>
                            <p id="privacy-option-description-4" class="text-sm leading-5 text-gray-500">
                                Adipiscing est venenatis enim molestie commodo eu gravid
                            </p>
                        </div>
                        <!-- On: "bg-teal-500", Off: "bg-gray-200" -->
                        <span role="checkbox" tabindex="0" aria-checked="true" aria-labelledby="privacy-option-label-4" aria-describedby="privacy-option-description-4" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline">
                                          <!-- On: "translate-x-5", Off: "translate-x-0" -->
                                          <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"></span>
                                        </span>
                    </li>
                </ul>
            </div>
            <div class="py-4 px-4 flex justify-end space-x-5 sm:px-6">
                <div class="inline-flex rounded-md shadow-sm">
                    <button type="button" class="bg-white border border-gray-300 rounded-md py-2 px-4 inline-flex justify-center text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-light-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                        Cancel
                    </button>
                </div>
                <div class="inline-flex rounded-md shadow-sm">
                    <button type="submit" class="bg-light-blue-700 border border-transparent rounded-md py-2 px-4 inline-flex justify-center text-sm leading-5 font-medium text-white hover:bg-light-blue-600 focus:outline-none focus:border-light-blue-800 focus:shadow-outline-blue active:bg-light-blue-800 transition duration-150 ease-in-out">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SettingsProfile"
    }
</script>

<style scoped>

</style>
